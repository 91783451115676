import { Link } from 'react-router-dom';
import './Footer.css';

function Footer(){
    return(
        <footer>
            <div className='footer-container'>
                <p>© {new Date().getFullYear()} City Pub Crawl</p>
                <ul>
                    <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                     <li> Discover more things to do in <a href="https://www.kayak.co.uk/Krakow.9856.guide" target="_blank">Krakow</a>.</li>
                     <li> Plan and book your travel on <a href="https://www.kayak.co.uk/cars" target="_blank">KAYAK</a>.</li>
                </ul>
            </div>
        </footer>
    )
}


export default Footer;
